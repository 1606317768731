.vertical-timeline-element-date {
  opacity: 1 !important;
}

.vertical-timeline-element-date h2 {
  font-size: 28px !important;
}

.vertical-timeline-element-date p {
  margin: 0;
}

.vertical-timeline {
  padding: 0 !important;
  margin: 0 !important;
  width: 100% !important;
}